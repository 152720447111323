@font-face {
  font-family: "feather";
  src: url("../../fonts/feather.ttf?2t6bn1") format("truetype"),
    url("../../fonts/feather.woff?2t6bn1") format("woff"),
    url("../../fonts/feather.svg?2t6bn1#feather") format("svg");
  font-weight: normal;
  font-style: normal;
}

.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather.x2 {
  font-size: 18px !important;
}

.feather.x3 {
  font-size: 22px !important;
  margin-top: -2px;
}

.feather.x3 {
  font-size: 32px !important;
}

.feather.activity:before {
  content: "\e900";
}
.feather.airplay:before {
  content: "\e901";
}
.feather.alert-circle:before {
  content: "\e902";
}
.feather.alert-octagon:before {
  content: "\e903";
}
.feather.alert-triangle:before {
  content: "\e904";
}
.feather.align-center:before {
  content: "\e905";
}
.feather.align-justify:before {
  content: "\e906";
}
.feather.align-left:before {
  content: "\e907";
}
.feather.align-right:before {
  content: "\e908";
}
.feather.anchor:before {
  content: "\e909";
}
.feather.aperture:before {
  content: "\e90a";
}
.feather.arrow-down-left:before {
  content: "\e90b";
}
.feather.arrow-down-right:before {
  content: "\e90c";
}
.feather.arrow-down:before {
  content: "\e90d";
}
.feather.arrow-left:before {
  content: "\e90e";
}
.feather.arrow-right:before {
  content: "\e90f";
}
.feather.arrow-up-left:before {
  content: "\e910";
}
.feather.arrow-up-right:before {
  content: "\e911";
}
.feather.arrow-up:before {
  content: "\e912";
}
.feather.at-sign:before {
  content: "\e913";
}
.feather.award:before {
  content: "\e914";
}
.feather.bar-chart-2:before {
  content: "\e915";
}
.feather.bar-chart:before {
  content: "\e916";
}
.feather.battery-charging:before {
  content: "\e917";
}
.feather.battery:before {
  content: "\e918";
}
.feather.bell-off:before {
  content: "\e919";
}
.feather.bell:before {
  content: "\e91a";
}
.feather.bluetooth:before {
  content: "\e91b";
}
.feather.book:before {
  content: "\e91c";
}
.feather.bookmark:before {
  content: "\e91d";
}
.feather.box:before {
  content: "\e91e";
}
.feather.briefcase:before {
  content: "\e91f";
}
.feather.calendar:before {
  content: "\e920";
}
.feather.camera-off:before {
  content: "\e921";
}
.feather.camera:before {
  content: "\e922";
}
.feather.cast:before {
  content: "\e923";
}
.feather.check-circle:before {
  content: "\e924";
}
.feather.check-square:before {
  content: "\e925";
}
.feather.check:before {
  content: "\e926";
}
.feather.chevron-down:before {
  content: "\e927";
}
.feather.chevron-left:before {
  content: "\e928";
}
.feather.chevron-right:before {
  content: "\e929";
}
.feather.chevron-up:before {
  content: "\e92a";
}
.feather.chevrons-down:before {
  content: "\e92b";
}
.feather.chevrons-left:before {
  content: "\e92c";
}
.feather.chevrons-right:before {
  content: "\e92d";
}
.feather.chevrons-up:before {
  content: "\e92e";
}
.feather.chrome:before {
  content: "\e92f";
}
.feather.circle:before {
  content: "\e930";
}
.feather.clipboard:before {
  content: "\e931";
}
.feather.clock:before {
  content: "\e932";
}
.feather.cloud-drizzle:before {
  content: "\e933";
}
.feather.cloud-lightning:before {
  content: "\e934";
}
.feather.cloud-off:before {
  content: "\e935";
}
.feather.cloud-rain:before {
  content: "\e936";
}
.feather.cloud-snow:before {
  content: "\e937";
}
.feather.cloud:before {
  content: "\e938";
}
.feather.codepen:before {
  content: "\e939";
}
.feather.command:before {
  content: "\e93a";
}
.feather.compass:before {
  content: "\e93b";
}
.feather.copy:before {
  content: "\e93c";
}
.feather.corner-down-left:before {
  content: "\e93d";
}
.feather.corner-down-right:before {
  content: "\e93e";
}
.feather.corner-left-down:before {
  content: "\e93f";
}
.feather.corner-left-up:before {
  content: "\e940";
}
.feather.corner-right-down:before {
  content: "\e941";
}
.feather.corner-right-up:before {
  content: "\e942";
}
.feather.corner-up-left:before {
  content: "\e943";
}
.feather.corner-up-right:before {
  content: "\e944";
}
.feather.cpu:before {
  content: "\e945";
}
.feather.credit-card:before {
  content: "\e946";
}
.feather.crosshair:before {
  content: "\e947";
}
.feather.delete:before {
  content: "\e948";
}
.feather.disc:before {
  content: "\e949";
}
.feather.download-cloud:before {
  content: "\e94a";
}
.feather.download:before {
  content: "\e94b";
}
.feather.droplet:before {
  content: "\e94c";
}
.feather.edit-2:before {
  content: "\e94d";
}
.feather.edit-3:before {
  content: "\e94e";
}
.feather.edit:before {
  content: "\e94f";
}
.feather.external-link:before {
  content: "\e950";
}
.feather.eye-off:before {
  content: "\e951";
}
.feather.eye:before {
  content: "\e952";
}
.feather.facebook:before {
  content: "\e953";
}
.feather.fast-forward:before {
  content: "\e954";
}
.feather.file-minus:before {
  content: "\e956";
}
.feather.file-plus:before {
  content: "\e957";
}
.feather.file-text:before {
  content: "\e958";
}
.feather.file:before {
  content: "\e959";
}
.feather.film:before {
  content: "\e95a";
}
.feather.filter:before {
  content: "\e95b";
}
.feather.flag:before {
  content: "\e95c";
}
.feather.folder:before {
  content: "\e95d";
}
.feather.github:before {
  content: "\e95e";
}
.feather.gitlab:before {
  content: "\e95f";
}
.feather.globe:before {
  content: "\e960";
}
.feather.grid:before {
  content: "\e961";
}
.feather.hash:before {
  content: "\e962";
}
.feather.headphones:before {
  content: "\e963";
}
.feather.heart:before {
  content: "\e964";
}
.feather.home:before {
  content: "\e965";
}
.feather.image:before {
  content: "\e966";
}
.feather.inbox:before {
  content: "\e967";
}
.feather.info:before {
  content: "\e968";
}
.feather.instagram:before {
  content: "\e969";
}
.feather.layers:before {
  content: "\e96a";
}
.feather.layout:before {
  content: "\e96b";
}
.feather.life-buoy:before {
  content: "\e96c";
}
.feather.link-2:before {
  content: "\e96d";
}
.feather.link:before {
  content: "\e96e";
}
.feather.list:before {
  content: "\e96f";
}
.feather.loader:before {
  content: "\e970";
}
.feather.lock:before {
  content: "\e971";
}
.feather.log-in:before {
  content: "\e972";
}
.feather.log-out:before {
  content: "\e973";
}
.feather.mail:before {
  content: "\e974";
}
.feather.map-pin:before {
  content: "\e975";
}
.feather.map:before {
  content: "\e976";
}
.feather.maximize-2:before {
  content: "\e977";
}
.feather.maximize:before {
  content: "\e978";
}
.feather.menu:before {
  content: "\e979";
}
.feather.message-circle:before {
  content: "\e97a";
}
.feather.message-square:before {
  content: "\e97b";
}
.feather.mic-off:before {
  content: "\e97c";
}
.feather.mic:before {
  content: "\e97d";
}
.feather.minimize-2:before {
  content: "\e97e";
}
.feather.minimize:before {
  content: "\e97f";
}
.feather.minus-circle:before {
  content: "\e980";
}
.feather.minus-square:before {
  content: "\e981";
}
.feather.minus:before {
  content: "\e982";
}
.feather.monitor:before {
  content: "\e983";
}
.feather.moon:before {
  content: "\e984";
}
.feather.more-horizontal:before {
  content: "\e985";
}
.feather.more-vertical:before {
  content: "\e986";
}
.feather.move:before {
  content: "\e987";
}
.feather.music:before {
  content: "\e988";
}
.feather.navigation-2:before {
  content: "\e989";
}
.feather.navigation:before {
  content: "\e98a";
}
.feather.octagon:before {
  content: "\e98b";
}
.feather.package:before {
  content: "\e98c";
}
.feather.paperclip:before {
  content: "\e98d";
}
.feather.pause-circle:before {
  content: "\e98e";
}
.feather.pause:before {
  content: "\e98f";
}
.feather.percent:before {
  content: "\e990";
}
.feather.phone-call:before {
  content: "\e991";
}
.feather.phone-forwarded:before {
  content: "\e992";
}
.feather.phone-incoming:before {
  content: "\e993";
}
.feather.phone-missed:before {
  content: "\e994";
}
.feather.phone-off:before {
  content: "\e995";
}
.feather.phone-outgoing:before {
  content: "\e996";
}
.feather.phone:before {
  content: "\e997";
}
.feather.pie-chart:before {
  content: "\e998";
}
.feather.play-circle:before {
  content: "\e999";
}
.feather.play:before {
  content: "\e99a";
}
.feather.plus-circle:before {
  content: "\e99b";
}
.feather.plus-square:before {
  content: "\e99c";
}
.feather.plus:before {
  content: "\e99d";
}
.feather.pocket:before {
  content: "\e99e";
}
.feather.power:before {
  content: "\e99f";
}
.feather.printer:before {
  content: "\e9a0";
}
.feather.radio:before {
  content: "\e9a1";
}
.feather.refresh-ccw:before {
  content: "\e9a2";
}
.feather.refresh-cw:before {
  content: "\e9a3";
}
.feather.repeat:before {
  content: "\e9a4";
}
.feather.rewind:before {
  content: "\e9a5";
}
.feather.rotate-ccw:before {
  content: "\e9a6";
}
.feather.rotate-cw:before {
  content: "\e9a7";
}
.feather.save:before {
  content: "\e9a8";
}
.feather.scissors:before {
  content: "\e9a9";
}
.feather.search:before {
  content: "\e9aa";
}
.feather.server:before {
  content: "\e9ab";
}
.feather.settings:before {
  content: "\e9ac";
}
.feather.share-2:before {
  content: "\e9ad";
}
.feather.share:before {
  content: "\e9ae";
}
.feather.shield:before {
  content: "\e9af";
}
.feather.shuffle:before {
  content: "\e9b0";
}
.feather.sidebar:before {
  content: "\e9b1";
}
.feather.skip-back:before {
  content: "\e9b2";
}
.feather.skip-forward:before {
  content: "\e9b3";
}
.feather.slack:before {
  content: "\e9b4";
}
.feather.slash:before {
  content: "\e9b5";
}
.feather.sliders:before {
  content: "\e9b6";
}
.feather.smartphone:before {
  content: "\e9b7";
}
.feather.speaker:before {
  content: "\e9b8";
}
.feather.square:before {
  content: "\e9b9";
}
.feather.star:before {
  content: "\e9ba";
}
.feather.stop-circle:before {
  content: "\e9bb";
}
.feather.sun:before {
  content: "\e9bc";
}
.feather.sunrise:before {
  content: "\e9bd";
}
.feather.sunset:before {
  content: "\e9be";
}
.feather.tablet:before {
  content: "\e9bf";
}
.feather.tag:before {
  content: "\e9c0";
}
.feather.target:before {
  content: "\e9c1";
}
.feather.thermometer:before {
  content: "\e9c2";
}
.feather.thumbs-down:before {
  content: "\e9c3";
}
.feather.thumbs-up:before {
  content: "\e9c4";
}
.feather.toggle-left:before {
  content: "\e9c5";
}
.feather.toggle-right:before {
  content: "\e9c6";
}
.feather.trash-2:before {
  content: "\e9c7";
}
.feather.trash:before {
  content: "\e9c8";
}
.feather.trending-down:before {
  content: "\e9c9";
}
.feather.trending-up:before {
  content: "\e9ca";
}
.feather.triangle:before {
  content: "\e9cb";
}
.feather.tv:before {
  content: "\e9cc";
}
.feather.twitter:before {
  content: "\e9cd";
}
.feather.type:before {
  content: "\e9ce";
}
.feather.umbrella:before {
  content: "\e9cf";
}
.feather.unlock:before {
  content: "\e9d0";
}
.feather.upload-cloud:before {
  content: "\e9d1";
}
.feather.upload:before {
  content: "\e9d2";
}
.feather.user-check:before {
  content: "\e9d3";
}
.feather.user-minus:before {
  content: "\e9d4";
}
.feather.user-plus:before {
  content: "\e9d5";
}
.feather.user-x:before {
  content: "\e9d6";
}
.feather.user:before {
  content: "\e9d7";
}
.feather.users:before {
  content: "\e9d8";
}
.feather.video-off:before {
  content: "\e9d9";
}
.feather.video:before {
  content: "\e9da";
}
.feather.voicemail:before {
  content: "\e9db";
}
.feather.volume-1:before {
  content: "\e9dc";
}
.feather.volume-2:before {
  content: "\e9dd";
}
.feather.volume-x:before {
  content: "\e9de";
}
.feather.volume:before {
  content: "\e9df";
}
.feather.watch:before {
  content: "\e9e0";
}
.feather.wifi-off:before {
  content: "\e9e1";
}
.feather.wifi:before {
  content: "\e9e2";
}
.feather.wind:before {
  content: "\e9e3";
}
.feather.x-circle:before {
  content: "\e9e4";
}
.feather.x-square:before {
  content: "\e9e5";
}
.feather.x:before {
  content: "\e9e6";
}
.feather.zap:before {
  content: "\e9e7";
}
.feather.zoom-in:before {
  content: "\e9e8";
}
.feather.zoom-out:before {
  content: "\e9e9";
}
.feather.key:before {
  content: "\e9ea";
}
.feather.help-circle:before {
  content: "\e9eb";
}
.feather.send:before {
  content: "\e9ec";
}
.feather.message-square-filled:before {
  content: "\e9ed";
}
.feather.archive:before {
  content: "\e9ee";
}
.feather.workspace:before {
  content: "\e9ef";
}
.feather.card-view:before {
  content: "\e9f0";
}
.feather.bell-filled:before {
  content: "\e9f1" !important;
}
.feather.pin:before {
  content: "\e9f2" !important;
}
