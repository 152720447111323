.panel {
	border-radius: 2px;
	margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
	.panel {
		margin-bottom: 15px;
	}
}

::-webkit-scrollbar {
  width: 11px;
	height: 11px;
}
::-webkit-scrollbar-button {
  width: 2px;
	height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #CED2D9;
  border: 0px none #ffffff;
	border-radius: 50px;
	border: 2px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #B0B7C2;
}
::-webkit-scrollbar-thumb:active {
  background: #B0B7C2;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #E8EBEE;
}
::-webkit-scrollbar-track:active {
  background: #E8EBEE;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.panel-default {
	background: transparent;
	border: none;
	/* border: 1px solid #e5e8ef; */
	box-shadow: 0 2px 4px 0 rgba(39,76,132,0.05);
	margin-bottom: 30px;
	/*border: 1px solid #f1f4f7;*/
}

.practice-info .panel-default {
	background-color: #fff;
	padding: 15px;
}

.panel-default > .panel-heading {
	font-size: 18px;
	background-color: transparent;
	color: #151C29;
	border-bottom: 1px solid rgba(229, 232, 239, 0.71);
	padding: 15px;
	font-weight: 700;
}

.panel-default > .panel-footer {
	background-color: transparent;
	color: #151C29;
	border-top: 1px solid rgba(229, 232, 239, 0.71);
	padding: 15px;
}

.panel-default .panel-heading .badge {
	background-color: #FF3B2D;
	color: #fff;
	font-size: 10px;
	vertical-align: top;
	margin-top: -4px;
	font-weight: 600;
	padding: 2px 5px;
}

.panel-heading .btn {
	float: right;
	margin: -5px -5px 0 0;
	padding: 6px 15px;
}

.panel-search {
	float: right;
    margin: -5px -5px 0 0;
    padding: 4px 0px;
    padding-left: 10px;
		border: 1px solid #f1f4f7;
		background: #fff;
    font-weight: 400;
	border-radius: 2px;
	font-size: 14px;
}

@media screen and (max-width: 420px) {
    .panel-search {
        width: 40%;
        white-space: nowrap;
    }

    .panel-search input {
        width: 90%;
    }
}

.panel-search i {
	color: #b3bac5;
	vertical-align: -1px;
}

.panel-search input {
	border: none;
	outline: none;
}

.panel-body {
	padding: 15px;
}

/* UI v2 */

.ch-panels .panel-default,
.ch-panels .panel-default > .panel-heading,
.ch-panels .panel-default .panel-body {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
	width: 100%;
}

.ch-panels .panel-default .panel-body {
	height: 100% !important;
	max-width: 100% !important;
	overflow: visible !important;
}

.ch-panels .manage-apps-icon-right {
	margin-top: 5px;
}

.ch-panels .panel-default > .panel-heading {
	font-size: 18px;
	font-weight: normal;
	color: #151C29;
	margin-bottom: 15px;
}

.ch-panels .panel-default > .panel-heading .filter {
	font-size: 14px;
}

.ch-panels .manage-apps-logo,
.ch-panels .manage-apps-card img {
	height: 32px !important;
	width: 32px !important;
	max-width: 32px !important;
    min-height: 32px !important;
	max-height: 32px !important;
	float: left;
    margin-right: 10px;
    margin-top: 4px;
}

.ch-panels .app-description {
	overflow: visible;
}

.ch-panels .app-name {
	margin: 0;
}

.ch-panels .apps-widget-item > div {
	max-width: 100%;
	margin: 0;
	margin-bottom: 15px;
}
