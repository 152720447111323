.avatar {
  width: 64px;
  height: 64px;
  border-radius: 64px !important;
  text-align: center;
  background-color: #b3bac5;
  color: #fff;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: default;
  text-transform: uppercase;
}

.avatar > .avatar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.avatar-bot {
  background: #b3bac5 url(../../img/bot.svg) center -1px no-repeat;
  background-size: cover;
}

.td-avatar {
  width: 42px;
}

.avatar-64 {
  line-height: 64px;
  font-size: 22px;
}

.avatar-64 > img {
  height: 64px;
}

.avatar-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
}

.avatar-48 > img {
  height: 48px;
}

.avatar-32 {
  line-height: 34px;
  width: 32px;
  height: 32px;
  font-size: 14px;
}

.avatar-32 > img {
  height: 32px;
}

.avatar-24 {
  line-height: 25px;
  width: 24px;
  height: 24px;
  font-size: 11px;
  background: rgba(55, 64, 76, 0.5);
}

.avatar-24 > img {
  height: 24px;
}

@media screen and (max-width: 767px) {
  .avatar-64 {
    line-height: 34px;
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  .avatar-64 > img {
    height: 32px;
  }
}

.avatar-40 > img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 100% !important;
}
