.modal-backdrop {
  background: rgba(30, 35, 40, 1);
  /*opacity: 1 !important;*/
  /* display: none */
}

.modal-header h4 {
  margin: 0;
}

.modal-body > h3 {
  margin-top: 40px;
}

.modal-body.rounded {
  border-radius: 16px !important;
}

.modal-body > h3:first-child {
  margin-top: 0;
}

.modal-header {
  border-bottom: 1px solid rgba(229, 232, 239, 0.71);
}

.modal-header .close {
  margin: 0;
  color: #151c29;
  opacity: 0.5;
}

.modal-header .close:hover {
  opacity: 1;
}

.modal-body {
  background: #f2f3f6;
  border-radius: 0;
  overflow: hidden;
}

.modal-body.white {
  background: #fff;
}

.modal-body:last-child {
  border-radius: 0 0 16px 16px;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-footer {
  border-top: 1px solid rgba(229, 232, 239, 0.71);
}

.modal-content {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 16px;
}

.modal-confirmation {
  margin-top: 100px;
}

.modal-confirmation .modal-body {
  background: #fff !important;
  color: #151c29;
  font-weight: 500;
}

.client-events-notifications {
  background: #f2f3f6;
  z-index: 2;
  position: relative;
  margin: -15px;
  margin-top: -20px;
  padding: 15px;
  padding-top: 30px;
}

.client-events-radios {
  margin: 15px 0;
  margin-left: 20px;
}

.btn-close .feather {
  display: none;
}

.modal-title {
  order: -1;
  font-size: 1.25rem !important;
}

.modal-footer .row {
  width: 100%;
  text-align: right;
}