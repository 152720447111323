@import "../node_modules/viewerjs/dist/viewer.css";
@import "@ctrl/ngx-emoji-mart/picker";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* You can add global styles to this file, and also import other style files */
/* Debug */
* {
  /*outline: 1px solid pink;*/
}

html,
body {
  height: 100%;
}

/* @media screen and (min-width: 1441px) {
    html {
        zoom: 125%;
    }
}  */

.m-0 {
  margin: 0;
}

.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.p-20 {
  padding: 20px;
}

.fake-body {
  /* min-height: 100% !important; */
  position: relative;
  flex-grow: 1;
  /* padding-bottom: 76px; */
}

.fake-body.white {
  background: #fff;
  min-height: 100%;
  height: auto;
  padding-bottom: 50px;
}

body {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.practice-sidebar-wrapper {
  display: flex;
}

.adjust-body-for-alerts {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.full-top-alert {
  background: #e33b4d;
  color: rgba(255, 255, 255, 0.85);
  padding: 5px 15px;
  text-align: center;
  position: relative;
  z-index: 99;
}

.full-top-alert .title {
  color: #fff;
  font-weight: bold;
}

.full-top-alert a {
  font-weight: bold;
  color: #fff !important;
  text-decoration: underline !important;
}

.has-message .clients-list-wrapper {
  top: 30px !important;
}

.has-message .ch-panels {
  height: calc(100vh - 147px) !important;
}

.has-message .ch-panel-content {
  height: calc(100vh - 204px) !important;
}

.has-message .client-chat {
  height: calc(100vh - 247px) !important;
}

@media (max-width: 767px) {
  .has-message .ch-panels {
    height: calc(100vh - 147px) !important;
  }

  .has-message .ch-panel {
    height: calc(100vh - 235px) !important;
  }

  .has-message .ch-panel-content {
    height: calc(100vh - 289px) !important;
  }

  .has-message .client-chat {
    height: calc(100vh - 324px) !important;
  }
}

hr {
  border-top: 1px solid rgba(179, 186, 197, 0.2);
}

.bg-grey {
  background: #f2f3f6;
}

.popover {
  border: 1px solid rgba(0, 0, 0, 0.06);
}

/* Background top bar */
.header-bar {
  background-color: #fff;
  border: 1px solid rgba(229, 232, 239, 0.71);
  padding: 40px 0;
  padding-bottom: 85px;
}

.clients-view-footer {
  background-color: #fff;
  border-top: 1px solid rgba(229, 232, 239, 0.71);
  padding: 20px 0;
  margin-top: 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .fake-body {
    /* padding-bottom: 106px; */
  }

  .clients-view-footer,
  .clients-view-footer * {
    text-align: center;
  }
}

.header-bar.header-bar-practice {
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

.welcome-user h2 {
  margin: 0;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-top: 8px;
}

.welcome-user {
  display: table;
}

.welcome-user-avatar-wrapper {
  display: table-cell;
  width: 79px;
  vertical-align: top;
}

.welcome-user-name-wrapper {
  display: table-cell;
  vertical-align: top;
}

.copy-button {
  margin-left: -25px;
  margin-top: 25px;
}

@media screen and (max-width: 480px) {
  .welcome-user {
    float: left;
  }

  .practice-header {
    text-align: left !important;
  }
}

@media screen and (max-width: 767px) {
  .copy-button {
    margin-left: 0;
    margin-top: 25px;
  }

  .welcome-user h2 {
    font-size: 18px;
  }

  .avatar-welcome-user {
    margin-top: 12px;
  }

  .welcome-user-avatar-wrapper {
    width: 47px;
  }

  .welcome-user {
    margin: auto;
  }
}

.header-bar * a {
  display: inline-block;
  padding: 5px 0;
  margin-right: 15px;
}

.practice-header {
  color: #151c29;
}

@media screen and (max-width: 767px) {
  .practice-header {
    text-align: center;
  }

  .practice-header > * {
    display: flex !important;
  }

  .practice-logo-urls {
    padding: 0 10px;
    margin-top: 0;
  }

  .header-bar * a {
    display: inline-block;
    padding: 5px 0;
    margin-right: 10px;
  }

  .practice-header .client-name h2 {
    font-size: 15px;
  }

  .header-bar {
    padding-top: 20px;
  }

  .welcome-user .col-2 {
    max-width: 50px;
  }
}

@media (max-width: 767px) {
  .header-bar-client-view {
    padding: 10px 0 !important;
    padding-bottom: 15px !important;
  }

  .practice-logo-name {
    height: auto;
  }

  .practice-logo-name img {
    max-width: 170px;
    max-height: 40px;
  }

  .welcome-user-name-wrapper {
    display: none;
  }

  .avatar-welcome-user {
    margin: 0 !important;
  }
}

.practice-logo-urls {
  margin-top: 10px;
}

.practice-logo-urls > * {
  padding: 5px;
  margin-right: 5px;
}

.panel-login {
  max-width: 100%;
  width: 400px;
  margin: auto;
  background: #fff;
  margin-top: -4px;
  box-shadow: 0 15px 30px 0 rgba(39, 76, 132, 0.1);
  border-radius: 24px;
}

.main {
  min-height: 100%;
  margin-bottom: -40px;
  padding-bottom: 60px;
}

.privacy-terms > a {
  margin: 0 10px;
  text-decoration: none;
  color: inherit;
}

.coming-soon {
  position: relative;
}

.coming-soon > * {
  position: relative;
  pointer-events: none;
  opacity: 0.3;
  transition: 300ms;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
	                                supported by Chrome and Opera */
}

.coming-soon:hover::before {
  opacity: 1;
}

.coming-soon:hover > * {
  opacity: 0.05;
}

.coming-soon::before {
  content: "Coming soon";
  position: absolute;
  left: 40px;
  top: 45%;
  right: 40px;
  text-align: center;
  font-size: 22px;
  z-index: 2;
  background: #fff;
  opacity: 0;
  transition: 300ms;
}

.filter {
  margin-left: 10px;
  color: #b3bac5;
  font-weight: 500;
  cursor: pointer;
}

.filter i {
  width: 15px;
  display: inline-block;
  margin-right: 5px;
}

.practice-sidebar {
  background: #edeef0;
  /* height: 100vh; */
  flex-grow: 1;
  width: 250px;
  position: relative;
  z-index: 9;
  transition: 150ms;
}

.cch-logo.collapsed {
  display: none;
}

.practice-sidebar.collapsed {
  width: 72px;
}

.practice-sidebar .dropown-user-sidebar {
  left: 0;
  right: 0;
}

.practice-sidebar.collapsed .dropown-user-sidebar {
  left: 0;
  right: auto;
}

.tooltip {
  pointer-events: none !important;
}

.nav-wrapper .tooltip {
  display: none !important;
}

.practice-sidebar.collapsed .nav-wrapper .tooltip {
  display: block !important;
}

.practice-sidebar.collapsed .nav-wrapper span {
  display: none;
}

.practice-sidebar a {
  display: block;
  position: relative;
}

.practice-sidebar.collapsed .nav-wrapper a i {
  font-size: 26px;
}

.practice-sidebar.collapsed .cch-logo {
  display: none;
}

.practice-sidebar.collapsed .cch-logo.collapsed {
  display: block;
}

.practice-sidebar.collapsed .menu-icon-wrapper {
  display: none;
}

.practice-sidebar .nav-wrapper.nav-wrapper-bottom {
  display: flex;
  align-items: flex-end;
}

.practice-sidebar .nav-wrapper.nav-wrapper-bottom a {
  display: block;
  width: 100%;
}

.practive-user-top {
  display: table;
}

.practice-user-top-info,
.practice-user-top-avatar,
.practice-user-top-chevron {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.practice-sidebar.collapsed .user-dropdown-name-wrapper {
  display: none;
}

.practice-user-top-info {
  color: #151c29;
  line-height: 1.3;
}

.practice-user-top-info-name,
.user-dropdown-name-wrapper,
.practice-user-top-info-practice {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-dropdown-name-wrapper {
  padding-left: 10px;
}

.user-dropdown-avatar-wrapper {
  width: 32px;
}

.practice-user-top-info-practice {
  font-weight: 500;
}

.practice-user-top-avatar {
  width: 42px;
}

.practice-user-top-chevron {
  width: 24px;
}

.menu-icon {
  font-size: 22px;
  vertical-align: -7px;
  display: none;
  margin-right: 5px;
}

.menu-icon.open:before {
  content: "\e9e6" !important;
}

@media screen and (max-width: 767px) {
  .menu-icon {
    display: inline-block;
  }
}

.mobile-menu {
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 65px;
  right: 0;
  bottom: 0;
  display: none;
  /* opacity: 0; */
  z-index: 2;
  padding: 15px 0;
  transition: 150ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.mobile-menu .mobile-menu-wrapper {
  display: none;
}

.mobile-menu.open .mobile-menu-wrapper {
  display: block;
}

.mobile-menu.open {
  display: block;
  /* opacity: 1; */
}

.mobile-menu .mobile-menu-links > a,
.mobile-menu .mobile-menu-clients > a:first-child {
  display: block;
  color: #7f8ea4;
  padding: 5px 20px;
  margin-bottom: 25px;
  margin-top: 14px;
  position: relative;
}

.mobile-menu .mobile-menu-links > a > i,
.mobile-menu .mobile-menu-clients > a:first-child > i {
  font-size: 24px;
  color: #7f8ea4;
}

.mobile-menu .mobile-menu-links > a > span,
.mobile-menu .mobile-menu-clients > a:first-child > span {
  vertical-align: 3px;
  display: inline-block;
  padding-left: 15px;
}

.mobile-menu .expands:after {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e929";
  position: absolute;
  right: 30px;
  font-size: 22px;
}

.mobile-menu .back:after {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e928";
  position: absolute;
  left: 10px;
  font-size: 22px;
}

.mobile-menu .back {
  padding-left: 40px !important;
}

.mobile-menu .back i {
  color: #0075ff !important;
}

.mobile-menu-links.closed {
  display: none;
}

.mobile-menu-clients {
  display: none;
}

.mobile-menu-clients.open {
  display: block;
}

.mobile-menu-clients * {
  animation-duration: 0 !important;
  animation-name: none !important;
}

.mobile-menu-clients .btn-new-client {
  margin-left: 0;
  right: 15px;
}

.mobile-menu-clients .clients-list-wrapper .clients-list {
  overflow: auto;
  position: fixed;
  display: block;
  left: 15px;
  right: 15px;
  top: 150px;
  bottom: 0px;
  width: auto;
  border: none;
  padding: 0;
  border-radius: 0;
}

.clients-loading {
  background: url(./assets/img/clients-list-loading.gif) repeat-y;
}

.cch-logo {
  margin-top: 3px;
  max-width: 125px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container-margin {
    margin-left: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 999px) {
  .container-margin {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .container-margin {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .container-margin {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1251px) and (max-width: 1300px) {
  .container-margin {
    margin-left: 50px;
  }
}

.client-info-header {
  background-color: #151c29;

  background: #224fa6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(45deg, #224fa6, #187ec0);
  background: -moz-linear-gradient(45deg, #224fa6, #187ec0);
  background: -o-linear-gradient(45deg, #224fa6, #187ec0);
  background: linear-gradient(45deg, #224fa6, #187ec0);

  padding: 15px;
  border-radius: 2px;
  margin-bottom: 15px;
  padding-top: 25px;
  color: #fff;
  z-index: 3;
  top: 100px;
}

.client-info-header-row a {
  color: #fff;
  opacity: 0.7;
}

.client-info-header-row a:hover {
  opacity: 1;
}

.client-info-header-fixed {
  margin: 0;
}

.client-info-header h3 {
  margin-top: -5px;
  color: #fff;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .client-info-header {
    background-color: #151c29;
    background: #224fa6;
    background: linear-gradient(45deg, #224fa6, #187ec0);
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 15px;
    margin-top: -10px;
    padding-top: 20px;
    color: #fff;
    z-index: 3;
    top: 86px;
  }

  .client-info-header h3 {
    margin-top: -5px;
    color: #fff;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.client-info-header h3 small {
  display: inline-block;
  margin-top: 4px;
  cursor: pointer;
  font-size: 14px;
}

.client-info-header h3 small:hover * {
  opacity: 1 !important;
}

.client-info-header h3 small span {
  color: #fff;
  vertical-align: middle;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .client-info-header-content-wrapper {
    float: none !important;
  }

  .client-info-header-row {
    display: none !important;
  }

  /* .client-info-header {
		padding: 15px;
		border-radius: 2px;
		margin-bottom: 15px;
		margin-top: -30px;
		padding-top: 55px;
		top: 100px;
	} */
}

.client-info-header-tabs {
  margin-bottom: -5px;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.client-info-header-tabs a {
  color: #fff;
  padding: 10px 5px;
  margin-right: 15px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  opacity: 0.5;
  border-bottom: 3px solid transparent;
  transition: 150ms;
  letter-spacing: 1px;
}

.client-info-header-tabs a.active {
  opacity: 1;
  border-color: #0075ff;
}

.client-info-header-tabs a:hover {
  opacity: 1;
}

.tab-template {
  height: 0;
  overflow: hidden;
}

.tab-template.active {
  /* display: block; */
  height: auto;
  overflow: visible;
}

.client-info-header-content-wrapper {
  display: table;
}

.client-info-header-icon {
  display: table-cell;
  vertical-align: top;
  width: 20px;
}

.client-info-header-text {
  display: table-cell;
  vertical-align: top;
}

.client-info-header-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.empty-placeholder {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100%;
  margin-top: -65vh;
  text-align: center;
  transform: translate3d(0, 0, 0);
}

.my-account-avatar {
  padding: 20px 0;
}

.my-account-avatar .avatar img[src=""] {
  display: none;
}

.my-account-avatar .avatar {
  cursor: pointer;
  position: relative;
}

.my-account-avatar .avatar:hover {
  background: #959ca7;
}

.my-account-avatar .avatar:before {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e922";
  position: absolute;
  left: 22px;
  top: -1px;
  opacity: 0.5;
}

.my-account-avatar .avatar input {
  position: absolute;
  left: -9999px;
}

/* Contrasting header text colors based on brand color background */
.bg-dark a {
  color: #fff;
  opacity: 0.7;
}

.bg-dark a:hover {
  opacity: 1;
}

.bg-dark .welcome-user-name-wrapper h2,
.bg-dark .practice-logo-name {
  color: #fff;
}

.bg-light a {
  color: #151c29;
  opacity: 0.7;
}

.bg-light a:hover {
  opacity: 1;
}

.bg-light .welcome-user-name-wrapper h2,
.bg-light .practice-logo-name {
  color: #151c29;
}

.default-tabs {
  margin-top: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  max-height: 33px;
  position: relative;
  border-bottom: 1px solid #e5e8ef;
}

/* .default-tabs:after {
    content: "";
    background: -moz-linear-gradient(0deg, transparent 0%, rgb(255, 255, 255) 100%);
    background: -webkit-linear-gradient(0deg, transparent 0%, rgb(255, 255, 255) 100%);
    background: -o-linear-gradient(0deg, transparent 0%, rgb(255, 255, 255) 100%);
    background: -ms-linear-gradient(0deg, transparent 0%, rgb(255, 255, 255) 100%);
    background: linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 100%);
    position: absolute;
    width: 30px;
    top: 0; bottom: 0; right: 0;
    pointer-events: none;
} */

.tabs-wrapper {
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 15px;
  padding-bottom: 8px;
}

.default-tabs a {
  color: #485364;
  padding: 7px 5px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  margin-right: 15px;
  font-size: 11px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  transition: 150ms;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
}

.default-tabs a.active {
  border-color: #0083ff;
  color: #151c29;
}

.default-tabs a.tabactive {
  border-color: #0083ff;
  color: #151c29;
}

.default-tabs a:hover {
  color: #151c29;
}

.panel-heading .default-tabs {
  margin-bottom: -16px;
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.input-client-name {
  background: transparent;
  border: none;
}

.loading {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(226, 228, 230, 0.8);
  position: fixed;
  z-index: 999997;
}

.loader,
.loader:before,
.loader:after {
  background: #0075ff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #0075ff;
  text-indent: -9999em;
  margin: -22px 0 0 -22px;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.tour-backdrop {
  position: absolute;
  z-index: 1100;
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.popover-navigation {
  display: none;
}

.tour-tour-5 .popover-navigation,
.tour-tour-6 .popover-navigation,
.tour-tour-7 .popover-navigation {
  display: block;
}

.tour-tour-5 .popover-navigation > button:last-child,
.tour-tour-6 .popover-navigation > button:last-child,
.tour-tour-7 .popover-navigation > button:last-child {
  display: none;
}

.tour-tour-5 .popover-navigation .btn-group {
  display: block;
}

.tour-tour-5 .popover-navigation .btn-group button:first-child {
  display: none;
}

.tour-tour-5 .popover-navigation .btn-group button:last-child {
  display: block;
  width: 100%;
  border-radius: 2px;
}

.tour-tour-6 .popover-navigation .btn-group {
  display: block;
}

.tour-tour-6 .popover-navigation .btn-group * {
  width: 100px;
}

.tour-tour-7 .popover-navigation button {
  display: none;
}

.tour-tour-7 .popover-navigation .btn-group {
  display: block;
}

.tour-tour-7 .popover-navigation .btn-group button:first-child {
  display: block;
  width: 100%;
  border-radius: 2px;
}

.tour-modal-backdrop {
  background: rgba(30, 35, 40, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
}

.tour-modal {
  background: #fff;
  position: fixed;
  z-index: 9999999999;
  left: 50%;
  top: 50%;
  width: 650px;
  max-width: 90%;
  transform: translate3d(-50%, -50%, 0);
  padding: 30px;
  border-radius: 5px;
  padding-right: 0;
}

.tour-modal .x {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
}

.tour-modal .mockup img {
  margin-top: -30px;
  margin-bottom: -30px;
}

@media (max-width: 669px) {
  .tour-modal {
    padding-right: 30px;
  }

  .tour-modal * {
    text-align: center !important;
  }

  .tour-modal .tour-modal-right-column {
    display: none;
  }
}

/* V2V2V2V2V2V2 */

.practice-sidebar {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(270deg, rgba(51, 60, 72, 0.05) 0%, rgba(51, 60, 72, 0) 7.2%), #EDEEF0; */
}

.nav-wrapper {
  flex-grow: 1;
}

.nav-wrapper a {
  padding: 8px 20px;
  color: #151c29;
}

.nav-wrapper a:hover {
  /* background: linear-gradient(270deg, rgba(51, 60, 72, 0.05) 0%, rgba(51, 60, 72, 0) 7.2%), #F4F5F7; */
}

.nav-wrapper a.active {
  font-weight: bold;
  /* background: linear-gradient(270deg, rgba(51, 60, 72, 0.05) 0%, rgba(51, 60, 72, 0) 7.2%), #F4F5F7; */
}

.nav-wrapper a.active i {
  color: #0075ff;
}

.nav-wrapper a i {
  font-size: 20px;
  width: 30px;
  display: inline-block;
  color: #9ba3b0;
}

.menu-icon-wrapper i {
  font-size: 20px;
}

.user-dropdown-wrapper {
  padding: 20px;
  cursor: pointer;
  color: #151c29;
}

.scrollable-y {
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
}

.m-15 {
  margin: 0 -15px;
}

.p-15 {
  padding: 0 15px;
}

.grv-widget-tag-bottom-left {
  width: 0px !important;
  height: 0px !important;
  overflow: hidden !important;
}

.clients-list-title {
  padding: 20px;
  padding-bottom: 0;
}

.clients-list-wrapper {
  margin: 0 -15px;
}

.clients-list-wrapper h1 {
  margin: 0;
}

.clients-list-item {
  border-radius: 0;
  margin: 0;
  border-color: #e5e8ef !important;
}

.workspaces .clients-list-item {
  border-radius: 0;
  margin: 0;
  border-color: #e5e8ef !important;
  display: flex;
  align-items: center;
}

.clients-list-item .sidebar-badges {
  margin-top: 0 !important;
}

.clients-list-item .clients-list-name {
  flex-grow: 1;
}

.clients-list-item.active {
  border: none;
  border-bottom: 1px solid #e5e8ef;
  background: #f4f5f7;
  padding-bottom: 13px;
  padding-left: 20px;
}

.clients-list-item.active .clients-list-name {
  font-weight: bold;
}

.accountant-client-header {
  padding: 20px 15px;
  margin: 0 -15px;
  height: 92px;
}

.accountant-client-header h2 {
  margin-bottom: 0;
  padding-right: 15px;
}

.workspaces-panels-wrapper {
  margin: 0 -15px;
}

.ch-panels {
  overflow: hidden !important;
}

/* 
.ch-tabs {
	overflow: hidden;
    max-height: 34px;
    position: relative;
    border-bottom: 1px solid #E5E8EF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
}
.ch-tabs > * {
	color: #485364;
	padding: 7px 5px;
	padding-top: 7px !important;
	padding-bottom: 7px !important;
	margin-right: 15px;
	font-size: 12px;
	font-weight: 500;
	border-bottom: 3px solid transparent;
	transition: 150ms;
	letter-spacing: 1px;
    text-transform: uppercase;
	white-space: nowrap;
	font-weight: bold;
	cursor: pointer;
	min-width: 50px;
    text-align: center;
}
.ch-tabs > *.active {
	border-color: #0083FF;
	color: #151C29;
	cursor: default;
}
.ch-tabs > *.tabactive {
	border-color: #0083FF;
	color: #151C29;
}
.ch-tabs > *:hover {
	color: #151C29;
} */

.tooltip-arrow {
  display: none;
}

.tooltip-inner {
  max-width: 500px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  /* white-space: nowrap; */
}

.pages-header {
  padding: 20px 15px;
  border-bottom: 1px solid #e5e8ef;
  display: flex;
  align-items: center;
}

.pages-header h1 {
  margin: 0;
}

.tracked-items-dashboard-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  text-align: center;
  /* padding-bottom: 15px; */
  border-bottom: 1px solid #e5e8ef;
}

.tracked-items-dashboard-wrapper * {
}

.tracked-items-dashboard-wrapper-header {
  padding-bottom: 15px;
  padding-bottom: 0;
}

.tracked-items-dashboard-client-wrapper {
  border-right: 1px solid #e5e8ef;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.tracked-items-dashboard-client {
  width: 200px;
  padding: 15px;
}

.tracked-items-dashboard-items .row {
  display: flex;
}

.tracked-items-dashboard-items .message-bubble-wrapper {
  margin-right: 0;
}

.tracked-items-dashboard-items [class*="col"] {
  padding: 0 15px 15px;
  border-right: 1px solid #e5e8ef;
}

.tracked-items-dashboard-items [class*="col"]:last-child {
  border: none;
  padding-right: 30px;
}

.tracked-items-dashboard-wrapper-header [class*="col"] {
  padding: 5px 15px;
  border-right: 1px solid #e5e8ef;
}

.tracked-items-dashboard-wrapper-header .tracked-items-dashboard-client {
  padding: 0;
}

.sidebar-badges {
  float: right;
  margin-top: 2px;
}

.workspaces-badges {
  float: right;
  margin-top: -2px;
  margin-left: 10px;
}

.sidebar-badges > div,
.workspaces-badges > div {
  display: inline-block !important;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  padding-top: 1px;
  position: relative;
  min-width: 20px;
  text-align: center;
  height: 15px;
}

.practice-sidebar.collapsed .nav-wrapper .sidebar-badges > div span {
  display: inline-block !important;
}

.practice-sidebar.collapsed .nav-wrapper .sidebar-badges {
  float: none;
  margin: 0;
  width: 72px;
  margin-left: -20px;
  text-align: center;
}

.badge-counter {
  position: relative;
  z-index: 2;
  height: 10px;
  display: inline-block;
}

.badge-messages {
  background: #0075ff;
  margin-right: 3px;
}

.badge-messages::before {
  position: absolute;
  top: 1px;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #0075ff;
}

.badge-messages::last-child {
  margin-right: 0;
}

.badge-tracked-items {
  background: #ff7800;
}

.panel-hidden .workspaces-badges {
  display: none;
}

.clients-list-wrapper-latest {
  position: fixed;
  width: 300px;
  top: 0;
  margin-left: -100%;
  bottom: 0;
  z-index: 8;
  background: #fff;
  transition: 150ms !important;
}

.clients-list-wrapper-latest .clients-list-title {
  padding-bottom: 20px;
}

.clients-list-wrapper-latest .message {
  overflow: hidden;
  max-height: 38px;
  color: #485364;
  position: relative;
}

.clients-list-wrapper-latest .message::after {
  content: "";
  position: absolute;
  right: 0;
  top: 20px;
  height: 20px;
  width: 25%;
  z-index: 2;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.clients-list-wrapper-latest .clients-list-item:hover .message::after,
.clients-list-wrapper-latest .active .message::after {
  background: linear-gradient(
    to left,
    rgba(244, 244, 246, 1) 0%,
    rgba(244, 244, 246, 0) 100%
  );
}

.clients-list-wrapper-latest .name-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clients-list-wrapper-latest .time-wrapper {
  white-space: nowrap;
  padding-left: 5px;
}

.clients-list-wrapper-latest .active * {
  font-weight: 600;
  color: #151c29;
}

.clients-list-wrapper-latest .active .time-wrapper {
  color: #0075ff;
  font-weight: normal;
}

/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */
/* V4V4V4V4V4V4V4V4 */

.ch-panel-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ch-panel-content-no-scroll {
}

.ch-panel-content-scrollable-wrapper {
  flex-grow: 1;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.ch-panel-content-scrollable {
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.testimonial .carousel {
  flex-grow: 1;
}

.testimonial .carousel .carousel-indicators {
  bottom: -30px;
}

.carousel-control {
  text-shadow: none !important;
  background: transparent !important;
  color: #0075ff !important;
}

.carousel-control.left {
  left: -41px !important;
}
.carousel-control.right {
  right: -41px !important;
}

.carousel-indicators li {
  margin: 1px 5px;
  background-color: #c9ccd1;
  border: 1px solid #c9ccd1;
}

.carousel-indicators .active {
  background-color: #0075ff;
  border-color: #0075ff;
}

.row.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.task-list-panel > .ch-panel-content {
  height: auto !important;
}

.dropdown ul {
  background-color: #fff !important;
}

.flag {
  background-image: url("assets/img/flags.jpeg") !important;
}

.mention-dropdown {
  padding: 4px 8px !important;
  border-radius: 16px !important;
  max-height: 200px !important;
  overflow: auto !important;
  background-color: #fff !important;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.client-information-card .card-content *,
.note-card .card-content * {
  margin: 0 !important;
  font-size: 14px !important;
  color: #2a3344 !important;
  font-family: "Poppins", sans-serif !important;
  text-align: left !important;
}

.screen-sharing-wrapper .agora_video_player {
  object-fit: contain !important;
  position: relative !important;
}

.compose-addresses {
  p-chips {
    width: 100%;
    min-height: 29px;
    .p-chips.p-component {
      width: 100%;
      input {
        font-size: 14px;
        font-family: "Poppins", sans-serif !important;
        padding-top: 1px !important;
      }
    }
    .p-inputtext {
      width: 100%;
      border: none !important;
      box-shadow: none !important;
      font-size: 14px;
      padding: 0;
      gap: 6px 0px;
    }
    .p-chips-token {
      color: #485364 !important;
      background-color: #fff !important;
      border-radius: 500px !important;
      border: 1px solid #d7dbe1 !important;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      padding-left: 10px !important;

      .pi-times-circle:before {
        content: "×";
        font-size: 20px;
        font-weight: bold;
        line-height: 12px;
        padding: 0 2px;
      }
    }
  }
}

.custom-data__manager-options--input {
  p-chips {
    width: 100%;

    .p-chips.p-component {
      display: block;
      .p-chips-mutiple-container {
        border: none !important;
        outline: none !important;
      }
      .p-inputwrapper-focus {
        border: none !important;
        outline: none !important;
      }

      .p-focus {
        border: none !important;
        outline: none !important;
      }

      .p-inputtext {
        border: none !important;
        outline: none !important;
      }
      input {
        width: 100%;
        font-size: 14px;
        font-family: "Poppins", sans-serif !important;
        padding-top: 1px !important;
      }
    }
    .p-chips-token {
      color: black !important;
      font-weight: 400 !important;
      font-size: 14px;
      background-color: #f4f4f4 !important;
      border-radius: 500px !important;
      border: none !important;
      font-weight: 500;
      font-family: "Poppins", sans-serif !important;
      padding: 4px 16px !important;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;

      .pi-times-circle {
        margin-bottom: 2.5px !important;
      }

      .pi-times-circle:before {
        content: "×";
        font-size: 20px;
        font-weight: bold;
        line-height: 12px;
        padding: 0 2px;
      }
    }
  }
}

.fields-container {
  p-dropdown {
    width: 100%;

    .p-dropdown-clearable {
      width: 100%;
      height: 43px;
      span {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  p-calendar {
    width: 100%;
    .p-calendar-w-btn,
    .p-calendar {
      input {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #000;

        &::placeholder {
          font-weight: 400 !important;
          color: #000 !important;
        }
      }
      height: 43px;
      width: 100% !important;
    }
    .p-inputtext {
      outline: none !important;
      height: 43px;
      width: 100% !important;
    }
  }
}

.input {
  p-calendar {
    width: 100%;
    .p-inputtext:enabled:focus {
      outline: none !important;
      border-color: #d7dbe1 !important;
      box-shadow: none;
    }

    .p-inputtext:enabled:hover {
      border-color: #d7dbe1 !important;
    }
    .p-calendar-w-btn,
    .p-calendar {
      input {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        text-align: center;
      }
      height: 43px;
      width: 100% !important;
      // border: 2px solid #d7dbe1;
      // border-radius: 12px;
    }
    .p-inputtext {
      border: 2px solid #d7dbe1;
      border-radius: 12px;
      height: 43px;
      width: 100% !important;
    }
  }
}

p-inputnumber,
.p-inputnumber {
  display: flex;
  flex: 1;
}

.p-inputnumber-input {
  flex: 1;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}

.compose-addresses {
  dl,
  ol,
  ul {
    margin: 0;
  }
}

.adjust-dropdown-end {
  right: -30px !important;
}
