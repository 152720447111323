.tooltip-inner {
	background: #15181d;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #15181d;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #15181d;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #15181d;
}

.message-bubble > .tooltip:not(.tooltip-bottom) {
    left: 57px !important;
    right: auto !important;
    transform: translate3d(0, -18px, 0px) !important;
}

.message-bubble.me .tooltip:not(.tooltip-bottom) {
    right: 20px !important;
    left: auto !important;
    transform: translate3d(0, -18px, 0px) !important;
}

.jobs-comment-wrapper .tooltip:not(.tooltip-bottom) {
    right: auto !important;
    left: 37px !important;
    transform: translate3d(0, -18px, 0px) !important;
}

.jobs-comment-wrapper .me + .tooltip:not(.tooltip-bottom) {
    left: auto !important;
    right: 0 !important;
    transform: translate3d(0, -18px, 0px) !important;
}