.widgets {
	margin-top: -58px;
	position: relative;
}

.widgets-practice {
	margin-top: 100px;
}

@media screen and (max-width: 767px) {
	.widgets-practice {
	    margin-top: 86px;
	}
}

/* Apps */
/* .apps-widget .panel-body {
	padding: 15px;
	max-height: 226px;
	overflow-y: auto;
    overflow-x: hidden;
} */

.apps-widget-item > div {
    margin: auto;
    margin-bottom: 15px;
	max-width: 120px;
}

.apps-widget-item a:hover .app-name {
	color: #0075FF;
}

.app-name {
    margin: 20px 0;
    margin-bottom: 5px;
    font-weight: 500;
	color: #151C29;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.app-description {
	margin-bottom: 5px;
    font-size: 14px;
    white-space: nowrap;
    color: #485364;
    overflow: hidden;
    text-overflow: ellipsis;
}

.interesting-data {
    font-size: 11px;
}

.slick-slide a:hover .app-name {
    color: #0075FF;
}

.slick-slide a {
	outline: none !important;
}

.slick-slide img {
    display: block;
    margin: auto;
    width: 64px;
    height: 64px;
}

@media screen and (max-width: 600px) {
    .apps-widget .panel-body {
        padding: 15px 0;
    }

    .app-description {
        font-size: 11px;
    }
}

.manage-apps-row {
	white-space: nowrap;
	overflow-y: visible;
	overflow-x: auto;
	padding: 15px;
	padding-top: 0;
	margin: 0 -15px;
	min-height: 177px;
}

.selected-apps {
	background: url(../../img/apps-placeholder.png) 15px top no-repeat;
}

.manage-apps-list:first-child {
	margin-bottom: 30px;
}

.manage-apps-filter {
	cursor: pointer;
    display: inline-block;
    white-space: nowrap;
}

.manage-apps-search {
	display: inline-block;
	margin-left: 15px;
}

@media screen and (max-width:480px) {
    .manage-apps-search {
        margin: 0;
        margin-bottom: 15px
    }
}

.manage-apps-search input {
	background: transparent;
	border: transparent;
	padding-left: 5px;
	outline: none
}

.manage-apps-card {
	background: #fff;
	box-shadow: 0 1px 2px 0 rgba(39,76,132,0.15);
	display: inline-block;
	width: 125px;
	padding: 8px 10px 10px;
	text-align: center;
	margin-right: 15px;
	transition: 150ms;
	border: 1px solid #edeff4;
	border-radius: 2px;
}

@media screen and (max-width: 768px) {
    .manage-apps-page .manage-apps-card {
        margin-right: 4px;
    }
}

.manage-apps-card:last-child {
	margin: 0;
}

.library .manage-apps-card:hover {
	border:1px solid #0075FF;
}

.manage-apps-card:hover .app-name {
	/* color: #0075FF; */
}

.manage-apps-card {
	cursor: pointer;
}

.manage-apps-card.active {
	background: #0075FF;
	cursor: pointer;
}

.manage-apps-card.active * {
	color: #fff !important;
}

.manage-apps-card.active.gu-mirror {
	transform: rotate(-4deg);
}

.manage-apps-icon {
	display: inline-block;
	text-align: left;
	float: left;
	clear: left;
	margin-bottom: 5px;
	margin-top: -4px;
    margin-left: -2px;
    font-size: 18px;
}

.manage-apps-icon-right {
	display: inline-block;
	text-align: right;
	float: right;
	clear: right;
	margin-bottom: 5px;
	margin-top: -4px;
    margin-right: -2px;
    color: #485364;
    font-size: 18px;
}

.manage-apps-icon-right-view {
    right: 4px;
    position: absolute;
}

i.manage-apps-icon-right {
	opacity: .4;
}

i.manage-apps-icon-right.enabled {
	opacity: 1;
	color: #ffc000 !important;
	/* text-shadow: 0 0 25px #ffc000, 0 0 25px #ffc000, 0 0 25px #ffc000 !important; */
}

.manage-apps-card .app-name {
	margin-top: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.manage-apps-card .app-description {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.manage-apps-logo, .manage-apps-card img {
	display: block;
	margin: auto;
	clear: both;
	pointer-events: none;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
}

.manage-apps-order {
	margin-top: 11px;
	cursor: pointer;
	color: #151C29;
}

.manage-apps-order .sort-by {
	font-weight: 500;
}

.manage-apps-order .dropdown-menu {
	right: 15px;
}

.new-app {
	height: 162px;
	vertical-align: top;
	padding-top: 37px;
	border: 1px dashed #929eb3;
	box-shadow: none;
	transition: 300ms;
}


/* Apps */

/* Communications */
.communications-widget {
	overflow: hidden;
}

.communications-widget .panel-body {
	max-height: 318px;
}

.bubble {
	background-color: #f7f8fa;
	border: 1px solid #eeeff1;
	padding: 12px 15px;
	border-radius: 19px;
	margin-bottom: 15px;
	display: table;
	width: 100%;
}
.bubble-attention {
	background-color: #e5f2ff;
	border-color: #a3d3ff; 
}

.bubble-message {
	margin-bottom: 15px;
}

.bubble-message:last-child {
	margin: 0;
}

.bubble-avatar,
.bubble-content {
	display: table-cell;
	vertical-align: top;
}

.bubble-avatar {
	width: 47px;
}

.bubble-action-right {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
	color: #0075FF;
	font-weight: 500;
}

.bubble-action-right:hover,
.bubble-action-right:focus {
	color: #006acd;
}

.bubble-message-sender-name {
	color: #151C29;
	font-weight: 500;
}

.bubble-message-sender-time {
	padding-left: 5px;
	font-weight: 400;
}

.bubble-message-text {
	color: #151C29;
}

.label-date-wrapper {
	text-align: center;
	position: relative;
	margin-top: 25px;
	margin-bottom: 10px;
}

.label-date-wrapper:first-child {
	margin-top: -27px !important; /* Make the first label reach the .panel-heading */
}

.label-date-wrapper::before {
	content: " ";
	position: absolute;
	left: 0; top: 50%; right: 0;
	height: 1px;
	background-color: #f1f4f7;
	margin-top: -1px;
	z-index: 0;
}

.label-date {
	background-color: #fff;
	padding: 3px 15px;
	border-radius: 19px;
	font-size: 13px;
	color: #151C29;
	text-align: center;
	display: inline-block;
	position: relative;
	z-index: 1;
}
/* Communications */

/* Resources */
.resources-widget .panel-body {
	height: 210px;
	overflow: auto;
	padding-bottom: 0;
}

.resource-blue {
    color: #0075FF !important;
}
.resource-green {
    color: #2CCA3C !important;
}
.resource-purple {
    color: #6c5ce7 !important;
}
.resource-orange {
    color: #FF7800 !important;
}

.resources-client-widget .panel-body {
	height: 306px;
}

.resources-widget .panel-body .row, .resources-modal .row {
    margin-left: -5px;
    margin-right: -5px;
}
.resources-widget .panel-body div[class^="col"], .resources-modal div[class^="col"] {
    padding-left: 5px; 
    padding-right: 5px;
}

.resources-item {
	margin-bottom: 15px;
	display: table;
	width: 100%;
}

.resources-widget .panel-body .resources-item {
	border-radius: 2px;
	transition: 150ms;
	margin-bottom: 15px;
}
.resources-widget .panel-body .resources-item:hover {
	border-color: #0075FF;
}

.resources-item:hover .resources-title {
	color: #0075FF;
}

.resources-thumb {
	vertical-align: top;
	display: table-cell;
	width: 42px;
}

.resources-type i {
	vertical-align: -1px;
	border-radius: 32px;
	width: 32px;
    height: 32px;
    display: block;
    font-size: 18px;
    color: #485364;
    text-align: center;
    line-height: 32px;
    background: #EBEDF0 !important;
    border-radius: 8px;
}

.resources-content {
	vertical-align: top;
	display: table-cell;
}

.resources-content-wrapper {
	height: 72px;
	overflow: hidden;
	position: relative;
	padding-top: 20px;
	margin-top: -3px;
}

.ch-panels .resources-content-wrapper {
	height: auto;
}

.resources-title {
	color: #151C29;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	top: 0; left: 0;
	max-width: 100%;
	text-overflow: ellipsis;
	position: absolute;
}

.resources-description {
	color: #485364;
	font-size: 14px;
	max-height: 35px;
	line-height: 1.3;
	overflow: hidden;
	word-break: break-word;
}

.resources-tags {}
.resources-tag {
	background: #979ea8;
	color: #fff;
	border-radius: 3px;
	padding: 0 4px;
	display: inline-block;
	font-size: 11px;
}


.selected-resources-list .resources-item .resources-tag {
	background: rgba(0,0,0,.3);
}

.manage-resources-list .resources-item {
	padding: 10px;
    padding-bottom: 1px;
	border-radius: 2px;
	background: #fff;
	transition: 150ms;
	border: 1px solid transparent;
	box-shadow: 0 1px 2px 0 rgba(39,76,132,0.15);
}

.manage-resources-list .resources-item:hover .resources-title {
	color: #151C29
}

.manage-resources-list .resources-item-add {
	border: 1px dashed #929eb3;
	box-shadow: none;
	transition: 300ms;
}

.manage-resources-list .resources-item-add .resources-type {
	height: 14px;
}

.manage-resources-list .resources-item:hover {
	border:1px solid #0075FF;
}

.selected-resources-list .resources-item {
	background: #0075FF;
	border: transparent !important;
}

.selected-resources-list .resources-item .resources-type,
.selected-resources-list .resources-item .resources-title,
.selected-resources-list .resources-item .resources-description {
	color: #fff !important;
	opacity: .8;
}

.selected-resources-list .resources-item .resources-title {
	opacity: 1;
}

.selected-resources-list,
.not-selected-resources-list {
	max-height: 300px;
	overflow: auto;
}

.col-sort-resource.gu-mirror {
	transform: rotate(-2deg);
	border-radius: 2px;
}
.col-sort-resource.gu-mirror * {
	color: #fff !important;
	/* background: #0075FF; */
}

.col-sort-resource.gu-mirror .resources-tag {
	background: rgba(0,0,0,.3);
}


.col-sort-resource.gu-mirror .resources-item {
	color: #fff !important;
	background: #0075FF;
	padding: 10px;
	padding-bottom: 1px;
	border-radius: 2px;
}

/* Resources */

/* Review */
.reviews-widget .panel-body {
	min-height: 113px;
}
.reviews-widget .panel-body a {
	display: inline-block;
	margin-bottom: 19px;
}
@media screen and (max-width: 767px) {
	.reviews-widget .panel-body {
		text-align: center;
	}
}
/* Review */

/* Clients list */
.clients-list-wrapper {
	/*min-width: 500px;*/
}

.btn-new-client {
	position: fixed;
	display: block;
	top: 94px;
	margin-left: 205px;
	z-index: 2;
}

.clients-list {
	overflow: auto;
	display: block;
	background: #fff;
	overflow: hidden;
	overflow-y: auto;
	height: calc(100vh - 92px);
}


.clients-list-search {
	position: fixed;
	top: 94px;
	width: 360px;
	padding: 9px 0;
	cursor: text;
}

.clients-list-search i {
	vertical-align: -1px;
}

.clients-list-search input {
	border: none;
	background-color: transparent;
	outline: none;
	padding-left: 5px;
	font-weight: 400;
}

.clients-list-item {
	background: transparent;
	padding: 13px 10px;
	cursor: pointer;
	display: block;
	border-bottom: 1px solid #f0f1f3;
	padding-left: 20px;
	margin: 0 5px;
}

/* .clients-list-item:last-child {
	border-bottom: transparent;
} */

.clients-list-item:hover {
	background: rgba(240, 241, 243, 0.76);
	border-radius: 2px;
}

.clients-list-item.active {
	background: rgba(240, 241, 243, 0.76);
	border-radius: 2px;
	border-left: 3px solid #0184ff;
	padding-left: 17px;
	border-bottom: none;
	padding-bottom: 14px;
}
.clients-list-item.active .clients-list-name {
	font-weight: 500;
}

.clients-list-name {
	/* font-weight: 500; */
	color: #151C29;
}

.clients-list-description {
	color: #485364;
	font-size: 13px;
	vertical-align: middle;
}

.clients-list-description .btn {
	margin-left: 5px;
}

.workspaces .clients-list-title {
	padding: 20px;
}

.workspaces .clients-list {
	height: calc(100vh - 65px);
}

.workspaces .clients-list-wrapper {
	
}

/* Clients list */